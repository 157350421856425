import { useApiClient } from '@/api'
import type {
  BlockConfigArgumentFragment,
  BlockConfigArgumentInputSchema,
  BlockConfigFragment,
  WorkflowDetailsFragment,
} from '@/generated/sdk'
import { BlockConfigArgumentType } from '@/generated/sdk'
import { useBlockTypes } from '@/workflow-edit/composables'
import { computed, type Ref } from 'vue'

export function useManageArguments(opts: {
  workflow: Ref<WorkflowDetailsFragment | null | undefined>
  config: Ref<BlockConfigFragment | null | undefined> | null
}) {
  const { client } = useApiClient()
  const { getBlockType } = useBlockTypes()

  const blockType = computed(() => getBlockType(opts.config?.value ?? null))

  const configArgs = computed(() => {
    if (!opts.config?.value) {
      return opts.workflow.value?.result ?? []
    } else {
      // output settings
      return opts.config?.value?.arguments ?? []
    }
  })

  const createArgSettings = computed(() => {
    if (opts.config?.value) return { blockConfig: { id: opts.config?.value.id } }
    return { workflow: { id: opts.workflow.value!.id } }
  })

  async function createArgument(input: BlockConfigArgumentInputSchema) {
    const result = await client.createBlockConfigArgument({ input })
    const newArg = result.createBlockConfigArgument
    configArgs.value.push(newArg)
    return newArg
  }

  async function saveArgument(
    name: string,
    argument: BlockConfigArgumentFragment | null,
    input: {
      argumentType?: BlockConfigArgumentType
      value?: string
    },
  ) {
    if (argument) {
      if (argument.name !== name) throw new Error('Cannot change the name of an argument')
      Object.assign(argument, input)
      await client.updateBlockConfigArgument({ input: argument })
    } else {
      const argumentType = BlockConfigArgumentType.Constant
      const blockTypeArg = blockType.value?.arguments.find((a) => a.name === name)
      const value = String(blockTypeArg?.defaultValue ?? '')
      await createArgument({ name, argumentType, value, ...createArgSettings.value, ...input })
    }
  }

  async function renameArgument(argument: BlockConfigArgumentFragment, name: string) {
    if (argument.name === name) return
    argument.name = name
    await client.updateBlockConfigArgument({ input: { id: argument.id, name } })
  }

  async function deleteArgument(id: string) {
    await client.deleteBlockConfigArgument({ id })
    const idx = configArgs.value.findIndex((a) => a.id === id)
    if (idx >= 0) configArgs.value.splice(idx, 1)
  }

  async function addExtraArgument(name: string, argumentType: BlockConfigArgumentType) {
    await createArgument({ name, argumentType, value: name, ...createArgSettings.value })
  }

  // Checks if the name of the new argument is not already taken
  const isNameTaken = (name: string) => configArgs.value.some((a) => a.name === name)

  const canAddExtraArgument = computed(() => {
    // If no block is selected, we are in the workflow.result. We can always add extra arguments.
    if (!opts.config?.value) return true
    // Only if the block has dynamic arguments, we can add extra arguments.
    return blockType.value?.dynamicArguments || false
  })

  return {
    configArgs,
    saveArgument,
    renameArgument,
    deleteArgument,
    addExtraArgument,
    isNameTaken,
    canAddExtraArgument,
  }
}
