import type { BlockConfigArgumentType, BlockConfigFragment, WorkflowDetailsFragment } from '@/generated/sdk'
import { BlockArgumentType } from '@/generated/sdk'
import type { Ref } from 'vue'
import type { SettingsField } from './useFieldGeneration'
import { useFieldGeneration } from './useFieldGeneration'
import { useManageArguments } from './useManageArguments'

export function useBlockSettingsField(opts: {
  workflow: Ref<WorkflowDetailsFragment | null | undefined>
  config: Ref<BlockConfigFragment | null | undefined>
}) {
  const { saveArgument, deleteArgument } = useManageArguments({
    workflow: opts.workflow,
    config: opts.config,
  })
  const { getParentField } = useFieldGeneration({ config: opts.config })

  function getFieldDelete(field: SettingsField) {
    if (field.namePath.length === 1 && field.argument && !field.blockTypeArg) {
      return async () => {
        await deleteArgument(field.argument!.id)
      }
    }
    const finalKey = field.namePath[field.namePath.length - 1]
    const isNumericKey = !isNaN(Number(finalKey))
    const parentField = getParentField(field)
    if (parentField && isNumericKey) {
      return async () => {
        const index = Number(finalKey)
        const array = JSON.parse(parentField.data ?? '') as unknown[]
        const newArray = [...array.slice(0, index), ...array.slice(index + 1)]
        await setField(parentField, JSON.stringify(newArray))
      }
    }
    const isFile = field.blockTypeArg?.argumentType === BlockArgumentType.File
    if (isFile && field.data) {
      return async () => {
        await setField(field, null)
      }
    }
  }

  async function setField(field: SettingsField, value: string | null) {
    const path = field.namePath

    let newValue: string | null = null

    if (field.argument && field.argument.name !== path[0]) throw new Error('Invalid argument')
    if (path.length < 2) {
      newValue = value
    } else {
      const parsed = tryParseJson(field.argument?.value)
      const newData = forceObjectOrArray(parsed, path[1]!)
      let current = newData as Record<string, unknown>
      for (let i = 1; i < path.length; i++) {
        const key = path[i]!
        if (i < path.length - 1) {
          current[key] = forceObjectOrArray(current[key], path[i + 1]!)
          current = current[key] as Record<string, unknown>
        } else {
          current[key] = value
        }
      }
      newValue = jsonString(newData)
    }
    const argName = field.namePath[0]!
    await saveArgument(argName, field.argument, { value: newValue ?? undefined })
  }

  function getCreateArrayItem(field: SettingsField) {
    if (field.blockTypeArg?.argumentType !== BlockArgumentType.Array) return
    return async () => {
      const arr = field.asArray ?? []
      const newItem = field.blockTypeArg?.items?.defaultValue ?? null
      const newArray = [...arr, newItem]
      await setField(field, JSON.stringify(newArray))
    }
  }

  async function setArgumentType(field: SettingsField, argumentType: BlockConfigArgumentType) {
    const argName = field.namePath[0]!
    await saveArgument(argName, field.argument, { argumentType })
  }

  function jsonString(json: unknown) {
    if (typeof json === 'string') return json
    if (json == null) return ''
    return JSON.stringify(json)
  }

  function forceObjectOrArray(value: unknown, key: string) {
    const numericKey = !isNaN(Number(key))
    if (numericKey) return Array.isArray(value) ? value : []
    const isObject = value && typeof value === 'object' && !Array.isArray(value)
    return isObject ? value : {}
  }

  function tryParseJson(data: undefined | string | null) {
    try {
      return data ? (JSON.parse(data) as unknown) : null
    } catch (e) {
      return null
    }
  }

  return { getFieldDelete, setField, getCreateArrayItem, setArgumentType }
}
