import { useApiClient } from '@/api'
import type { BlockConfigFragment } from '@/generated/sdk'
import { useFieldGeneration, type SettingsField } from '@/workflow-edit/sidebar-right/block-sidebar'
import { ref, watch, type Ref } from 'vue'

export function useRunBlockConfigForm(opts: { config: Ref<BlockConfigFragment | null | undefined> }) {
  const { client } = useApiClient()
  const { generatedFields } = useFieldGeneration({ config: opts.config })

  const latestInput = ref<Record<string, unknown>>({})

  watch(() => opts.config.value?.id, fetchLatestInput, { immediate: true })

  async function fetchLatestInput() {
    if (!opts.config.value) return
    const response = await client.getBlockConfigOutput({
      blockConfigId: opts.config.value.id,
      runArgs: { size: 1, page: 1 },
    })
    const prevInput = response.blockConfig[0]?.runs[0]?.input

    generatedFields.value.forEach((field) => {
      if (prevInput && typeof prevInput === 'object') {
        const argName = field.namePath.join('.')
        latestInput.value[argName] = prevInput[argName as keyof typeof prevInput]
      }
    })
  }

  function getFieldInput(field: SettingsField) {
    return latestInput.value[field.namePath.join('.')]
  }

  function setFieldData(field: SettingsField, value: unknown) {
    latestInput.value[field.namePath.join('.')] = value
  }

  async function submitRun() {
    await client.runByBlockConfigId({
      blockConfigId: opts.config.value!.id,
      input: latestInput.value,
    })
  }

  return {
    generatedFields,
    submitRun,
    getFieldInput,
    setFieldData,
  }
}
