<script setup lang="ts">
import type { BlockConfigFragment, FileDetailsFragment, WorkflowDetailsFragment } from '@/generated/sdk'
import { BlockArgumentInputType, BlockConfigArgumentType } from '@/generated/sdk'
import { FileUploadModal, LargeModalLayout } from '@/ui/components'
import TwinIcon from '@/ui/components/TwinIcon.vue'
import { useUtils } from '@/ui/composables'
import { PromptField } from '@/workflow-edit/prompt'
import { Button, Column, FormItem, Row, Select, Textarea, TextInput } from '@madxnl/dodo-ui'
import { computed, ref, toRefs, watchEffect } from 'vue'
import ChooseDatasource from './ChooseDatasource.vue'
import { useBlockSettingsField } from './composables/useBlockSettingsField'
import type { SettingsField } from './composables/useFieldGeneration'

const props = defineProps<{
  workflow: WorkflowDetailsFragment
  config: BlockConfigFragment | null
  field: SettingsField
  disabled: boolean
  nestedLevel?: number
  onSwapItemOrder?: (direction: number) => void
  enableSwapItemOrder?: (direction: number) => boolean
}>()

const label = computed(() => {
  const isArrayIndex = !isNaN(Number(name.value))
  if (isArrayIndex) return `Item ${Number(name.value) + 1}`
  return name.value
})

const { debounce } = useUtils()
const { config, workflow } = toRefs(props)
const { getCreateArrayItem, getFieldDelete, setArgumentType, setField } = useBlockSettingsField({
  config,
  workflow,
})

const newValue = ref(props.field.data)
const textareaModalOpen = ref(false)
const fileuploadModalOpen = ref(false)
const fileArrayuploadModalOpen = ref(false)
const isReference = computed(() => props.field.argument?.argumentType === BlockConfigArgumentType.Reference)

const blockTypeArg = computed(() => props.field.blockTypeArg)
const placeholder = computed(() => String(blockTypeArg.value?.defaultValue ?? ''))
const inputType = computed(() => blockTypeArg.value?.inputType ?? BlockArgumentInputType.Text)
const name = computed(() => props.field.namePath[props.field.namePath.length - 1]!)

const availableOptions = computed(() => {
  const typeArg = blockTypeArg.value
  if (typeArg?.inputType === BlockArgumentInputType.Checkbox) {
    return [
      { label: 'True', value: 'true' },
      { label: 'False', value: 'false' },
    ]
  }
  if (typeArg?.options && typeArg?.inputType === BlockArgumentInputType.Select) {
    return typeArg.options.map((option) => ({
      value: option.value as string,
      label: option.label,
    }))
  }
  return null
})

const deleteField = computed(() => getFieldDelete(props.field))
const createArrayItem = computed(() => getCreateArrayItem(props.field))
const isSubfield = computed(() => (props.nestedLevel ?? 0) > 0)
const collapsable = computed(() => isSubfield.value && (props.field.items?.length || props.field.properties?.length))
const collapsed = ref(isSubfield.value)
const chooseDatasourceTrigger = ref(false)

const isFileArray = computed(() => {
  if (!props.field.items?.length) return false
  return props.field.items.every((x) => x.blockTypeArg?.inputType === BlockArgumentInputType.File)
})

watchEffect(() => {
  newValue.value = props.field.data
})

async function saveChanges() {
  await setField(props.field, newValue.value)
}

const saveDebounced = debounce(saveChanges)

function setValue(value: string | null) {
  newValue.value = value
  saveDebounced.trigger()
}

async function setReference(value: string) {
  await setArgumentType(props.field, BlockConfigArgumentType.Reference)
  setValue(value)
}

async function setFileId(files: FileDetailsFragment[]) {
  const file = files[0]
  setValue(file ? file.id : '')
  fileuploadModalOpen.value = false
}

async function setFileArray(files: FileDetailsFragment[]) {
  const fileIds = files.map((x) => x.id)
  setValue(JSON.stringify(fileIds))
  fileArrayuploadModalOpen.value = false
}

async function makeReference() {
  await setArgumentType(props.field, BlockConfigArgumentType.Reference)
  chooseDatasourceTrigger.value = true
}

async function cancelReference() {
  await setArgumentType(props.field, BlockConfigArgumentType.Constant)
}

async function swapItemOrder(index: number, direction: number) {
  const newData = [...(props.field.asArray ?? [])]
  const temp = newData[index]
  const newIndex = index + direction
  if (newIndex < 0 || newIndex >= newData.length) return
  newData[index] = newData[newIndex]
  newData[newIndex] = temp
  setValue(JSON.stringify(newData))
}
</script>

<template>
  <Column gap="s">
    <Row justify="between" :class="collapsable ? $style.collapsableLabel : ''" @click="collapsed = !collapsed">
      <Row gap="xs">
        <TwinIcon v-if="collapsable" :icon="collapsed ? 'ChevronRight' : 'ChevronDown'" size="s" />
        <span class="dodo-label-text">{{ label }}</span>
      </Row>
      <Row gap="0" :class="$style.labelButtons">
        <Button
          v-if="onSwapItemOrder"
          square
          size="s"
          variant="link"
          :disabled="enableSwapItemOrder?.(1) === false"
          title="Move down"
          @click.stop="onSwapItemOrder(1)"
        >
          <TwinIcon icon="ChevronDown" size="s" />
        </Button>
        <Button
          v-if="onSwapItemOrder"
          square
          size="s"
          variant="link"
          :disabled="enableSwapItemOrder?.(-1) === false"
          title="Move up"
          @click.stop="onSwapItemOrder(-1)"
        >
          <TwinIcon icon="ChevronUp" size="s" />
        </Button>
        <Button
          v-if="deleteField"
          :disabled="disabled"
          square
          size="s"
          variant="link"
          @click.stop.prevent="deleteField!"
        >
          <TwinIcon icon="Delete" size="s" />
        </Button>
        <Button v-if="createArrayItem" :disabled="disabled" square size="s" variant="link" @click="createArrayItem">
          <TwinIcon icon="Plus" />
        </Button>
      </Row>
    </Row>

    <Column v-if="!collapsable || !collapsed" gap="s">
      <template v-if="isReference">
        <ChooseDatasource
          v-model:trigger="chooseDatasourceTrigger"
          :value="field.argument!.value"
          :disabled="disabled"
          @select="setReference"
        />
      </template>

      <TextInput
        v-else-if="isFileArray"
        :model-value="typeof newValue === 'string' ? newValue : ''"
        :disabled="disabled"
        :placeholder="placeholder"
        style="flex: 1"
        @update:model-value="setValue"
        @click="fileArrayuploadModalOpen = true"
      />

      <Column v-else-if="field.items" gap="m" :class="$style.nestedContent">
        <template v-for="(item, i) of field.items" :key="String(item.namePath)">
          <BlockSettingsField
            :workflow="workflow"
            :config="config"
            :field="item"
            :disabled="disabled"
            :nested-level="(nestedLevel ?? 0) + 1"
            :on-swap-item-order="(dir: number) => swapItemOrder(i, dir)"
            :enable-swap-item-order="(dir: number) => i + dir >= 0 && i + dir < field.items!.length"
          />
        </template>
      </Column>

      <Column v-else-if="field.properties" gap="m" :class="$style.nestedContent">
        <BlockSettingsField
          v-for="subfield of field.properties"
          :key="String(subfield.namePath)"
          :workflow="workflow"
          :config="config"
          :field="subfield"
          :disabled="disabled"
          :nested-level="(nestedLevel ?? 0) + 1"
        />
      </Column>

      <Select
        v-else-if="availableOptions && (inputType === 'Select' || inputType === 'Checkbox')"
        :model-value="typeof newValue === 'string' ? newValue : ''"
        :disabled="disabled"
        :options="availableOptions"
        :placeholder="placeholder"
        style="flex: 1"
        @update:model-value="setValue"
      />

      <Button
        v-else-if="inputType === 'TextArea'"
        :aria-label="`${disabled ? 'View' : 'Edit'} ${name}`"
        @click="textareaModalOpen = true"
      >
        <TwinIcon icon="Edit" size="s" />
        {{ disabled ? 'View' : 'Edit' }} {{ name }}
      </Button>

      <PromptField
        v-else-if="inputType === 'PromptEditor'"
        :workflow="workflow"
        :config="config ?? null"
        :disabled="disabled"
        :field="field"
        @changevalue="setValue"
      />

      <template v-else-if="inputType === 'File'">
        <TextInput
          :model-value="typeof newValue === 'string' ? newValue : ''"
          :disabled="disabled"
          :placeholder="placeholder"
          style="flex: 1"
          @click="fileuploadModalOpen = true"
        />
      </template>

      <TextInput
        v-else
        :model-value="typeof newValue === 'string' ? newValue : ''"
        :disabled="disabled"
        :placeholder="placeholder"
        style="flex: 1"
        @update:model-value="setValue"
      />

      <Row v-if="field.namePath.length === 1">
        <Button
          v-if="isReference"
          :class="$style.dataSourceBtn"
          variant="link"
          :disabled="disabled"
          @click="cancelReference"
        >
          Disconnect data source
        </Button>
        <Button v-else :class="$style.dataSourceBtn" variant="link" :disabled="disabled" @click="makeReference"
          >Connect data source</Button
        >
      </Row>
    </Column>
  </Column>

  <LargeModalLayout
    :open="textareaModalOpen"
    :title="`${disabled ? 'View' : 'Edit'} ${name}`"
    @close="textareaModalOpen = false"
  >
    <template #content>
      <FormItem :label="name">
        <Textarea
          :model-value="typeof newValue === 'string' ? newValue : ''"
          :disabled="disabled"
          :placeholder="placeholder"
          :max-rows="20"
          @update:model-value="setValue"
        />
      </FormItem>
    </template>
    <template #footer="{ close }">
      <Row>
        <Button variant="solid" color="primary" @click="close">Done</Button>
      </Row>
    </template>
  </LargeModalLayout>

  <FileUploadModal
    :multiple="false"
    :open="fileuploadModalOpen"
    @close="fileuploadModalOpen = false"
    @files-created="setFileId"
  />

  <FileUploadModal
    :multiple="true"
    :open="fileArrayuploadModalOpen"
    @close="fileArrayuploadModalOpen = false"
    @files-created="setFileArray"
  />
</template>

<style module>
.dataSourceBtn {
  color: var(--grey-4-disabled);
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
}
.dataSourceBtn:hover {
  color: var(--grey-5-text);
}
.collapsableLabel {
  cursor: pointer;
  height: 32px;
}
.collapsableLabel:hover {
  color: black;
}
.labelButtons button:not(:hover) {
  color: var(--grey-4-disabled);
}
.nestedContent {
  padding-left: 16px;
}
</style>
